import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import ComingSoon from "../components/comingSoon"
import TikTokVideos from "../components/tikTokVideos"
import FunAF from "../components/funAF"
import OasiGrid from "../components/oasiGrid"
import Media from "../components/media"
import BackgroundImage from "gatsby-background-image"

const Home = () => {
  const { heroBgSm, heroBgMd } = useStaticQuery(graphql`
    query {
      heroBgSm: file(relativePath: { eq: "background.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroBgMd: file(relativePath: { eq: "background-lg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    heroBgSm.childImageSharp.fluid,
    {
      ...heroBgMd.childImageSharp.fluid,
      media: `(min-width: 600px)`,
    },
  ]

  return (
    <Layout>
      <BackgroundImage Tag="section" fluid={sources} preserveStackingContext>
        <div style={{ minHeight: "100vh", minWidth: "100vw" }}></div>
      </BackgroundImage>
      <ComingSoon />
      <TikTokVideos />
      <FunAF />
      <OasiGrid />
      <Media />
    </Layout>
  )
}

export default Home
